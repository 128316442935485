module MinGat.App.Public {
    export class LoginModel {
        private afterLoggedInUrl: string;
        private defaultLoadingText: string;

        Username: KnockoutObservable<string>;
        Password: KnockoutObservable<string>;
        TwoFactorCode: KnockoutObservable<string>;
        TwoFactorChallengeType: KnockoutObservable<number>;
        CurrentPageIndex: KnockoutComputed<number>;
        LoadedPage: KnockoutObservable<number>;
        StatusMessage: KnockoutObservable<string>;
        StatusSymbol: KnockoutObservable<string>;
        StatusSymbolTextClass: KnockoutComputed<string>;
        Progress: KnockoutObservable<any>;
        ProgressText: KnockoutObservable<string>;
        Errors: KnockoutValidationErrors;
        LoginDataSource: MinGat.WebApi.LoginDataSource;
        OneTimePasswordModel: KnockoutObservable<OneTimePasswordModel>;
        ChangePasswordModel: KnockoutObservable<ChangePasswordModel>;
        AllreadyLoggedIn: KnockoutObservable<boolean>;
        ProfileImageSource: KnockoutComputed<string>;
        LoggedInName: KnockoutObservable<string>;
        LoggedInUsername: KnockoutObservable<string>;
        EmployeeNumberCaption: KnockoutObservable<string>;
        CanResetPassword: KnockoutObservable<boolean>;
        CanSendSmsOnResetPassword: KnockoutObservable<boolean>;
        SendAlternatives: KnockoutObservableArray<any>;
        SelectedSendAlternative: KnockoutObservable<any>;
        CanSendEmailOnResetPassword: KnockoutObservable<boolean>;
        PasswordDeliveryMethodLoaded: KnockoutObservable<boolean>;
        ChangePassordOnLogin: KnockoutObservable<boolean>;
        ForcedPasswordChange: KnockoutObservable<boolean>;
        BackgroundImage: KnockoutObservable<string>;
        LoginFormLogo: KnockoutObservable<string>;
        ShowMoreOnLoginFailed: KnockoutObservable<boolean>;

        TwoFactorViewModel: Areas.Common.TwoFactorViewModel;

        isValid: KnockoutComputed<boolean>;

        gotoForgotPasswordPage: () => void;
        gotoFirstPage: () => void;
        gotoPrevPage: () => void;
        gotoChangePasswordPage: () => void;
        gotoTwoFactorPage: () => void;
        returnToStartPage: () => void;
        requestOneTimePassword: () => void;
        changePassword: () => void;
        login: () => JQueryPromise<any>;
        verifyCodeAndContinue: (twoFactorCode: string, twoFactorChallengeType: number) => JQueryPromise<any>;
        logout: () => void;

        constructor(enterUrl: string, bgImageUrl: string, loginFormLogoUrl: string) {
            var model = this;
            this.afterLoggedInUrl = enterUrl;
            this.LoginDataSource = new MinGat.WebApi.LoginDataSource();
            this.Username = ko.observable<string>().extend({ required: true });
            this.Password = ko.observable<string>().extend({ required: true });
            this.TwoFactorCode = ko.observable<string>().extend({ required: false });
            this.TwoFactorChallengeType = ko.observable<number>(null);
            this.StatusMessage = ko.observable("");
            this.StatusSymbol = ko.observable("");
            this.Progress = ko.observable().extend({ waitScope: true });
            this.defaultLoadingText = Gatsoft.UI.Resources.get("mg.login.loading") || "Loading...";
            this.ProgressText = ko.observable(this.defaultLoadingText);
            this.TwoFactorViewModel = new Areas.Common.TwoFactorViewModel((twoFactorCode: string, twoFactorChallengeType: number) => this.verifyCodeAndContinue(twoFactorCode, twoFactorChallengeType), this.Progress);
            this.Errors = ko.validation.group(this);
            this.AllreadyLoggedIn = ko.observable(false);
            this.ProfileImageSource = ko.computed<string>(() => {
                if (this.AllreadyLoggedIn())
                    return "~/api/public/login/GetProfileImage";
                return "";
            });
            this.LoggedInName = ko.observable("");
            this.LoggedInUsername = ko.observable("");
            this.CanResetPassword = ko.observable(false); // default
            this.EmployeeNumberCaption = ko.observable(Gatsoft.UI.Resources.get("mg.login.otp.empcode")); //default
            this.CanSendSmsOnResetPassword = ko.observable(false);
            this.CanSendEmailOnResetPassword = ko.observable(false);
            this.PasswordDeliveryMethodLoaded = ko.observable(false);
            this.ChangePassordOnLogin = ko.observable(false);
            this.ForcedPasswordChange = ko.observable(false);
            this.BackgroundImage = ko.observable("url('" + bgImageUrl + "')");
            this.LoginFormLogo = ko.observable("url('" + loginFormLogoUrl + "')");
            this.SendAlternatives = ko.observableArray([]);
            this.SelectedSendAlternative = ko.observable();
            this.ShowMoreOnLoginFailed = ko.observable(false);

            this.StatusSymbolTextClass = ko.computed(function () {
                if (model.StatusSymbol() != '')
                    return 'text-' + model.StatusSymbol();

                return 'text-error';
            });

            var currentPageIndex = ko.observable(0);
            this.OneTimePasswordModel = ko.observable(new OneTimePasswordModel());
            this.ChangePasswordModel = ko.observable(new ChangePasswordModel(this.Username, this.LoginDataSource, this.Progress));

            this.SelectedSendAlternative.subscribe((value) => {
                this.OneTimePasswordModel().DeliveryMethod(value.id);
            });

            (<any>currentPageIndex).__prevIndex = currentPageIndex();

            currentPageIndex.subscribe(function (newIndex) {
                if (newIndex != 0 && newIndex != 3) {
                    model.setError("");
                }

                switch (newIndex) {
                    case -2:
                        break;
                    case -1:
                        break;
                    case 0:
                        model.ChangePasswordModel().OldPassword("");
                        break;
                    case 1:
                        break;
                    case 3:
                        model.ChangePasswordModel().NewPassword("");
                        model.ChangePasswordModel().NewPasswordAgain("");

                        //clear validation messages
                        model.ChangePasswordModel().NewPassword.isModified(false);
                        model.ChangePasswordModel().NewPasswordAgain.isModified(false);
                        break;
                    case 4:
                        model.ProgressText(model.defaultLoadingText);
                        model.TwoFactorViewModel.initTwoFactorForLogin(model.Username());
                        break;
                    default:
                        break;
                }

                (<any>currentPageIndex).__prevIndex = newIndex;
            });

            this.CurrentPageIndex = ko.computed(function () {
                return currentPageIndex();

            });

            // Animations between pages can take some time, and setting focus while animating css
            // produces strange side-effects in may (all) browsers. Therefore we use LoadedPage together with
            // the setFocus binding to avoid the problem. (ex: setFocus: LoadedPage() == '1')
            this.LoadedPage = ko.observable<number>();

            model.Progress.waitScope.waitWhile(function (session) {
                var allreadyLoggedIn = false;
                var loggedInTask = model.LoginDataSource.IsLoggedIn().then(
                    function (data) {
                        allreadyLoggedIn = !!data;
                    });

                loggedInTask.then(() => {
                    if (allreadyLoggedIn && Application.autoLogoutMode) {
                        var logoutTask = model.LoginDataSource.Logout();
                        logoutTask.then((data) => {
                            WebApi.WebApiClientBase.antiForgeryToken = data;
                            MinGat.getUserContext().done(
                                function (userContext) {
                                    if (userContext != null) {
                                        model.LoggedInName(userContext.DisplayName());
                                        model.LoggedInUsername(userContext.Username());
                                        model.AllreadyLoggedIn(true);
                                    } else {
                                        model.LoggedInName("");
                                        model.LoggedInUsername("");
                                        model.AllreadyLoggedIn(false);
                                    }
                                }).fail(() => {
                                    model.AllreadyLoggedIn(false);
                                });
                        });
                        return;
                    } else {
                        MinGat.getUserContext().done(
                            function (userContext) {
                                if (userContext != null) {
                                    model.LoggedInName(userContext.DisplayName());
                                    model.LoggedInUsername(userContext.Username());
                                    model.AllreadyLoggedIn(true);
                                } else {
                                    model.LoggedInName("");
                                    model.LoggedInUsername("");
                                    model.AllreadyLoggedIn(false);
                                }
                            }).fail(() => {
                                model.AllreadyLoggedIn(false);
                            });
                    }
                }).always(() => {
                    session.end();
                });
            });

            model.Progress.waitScope.waitWhile(function (session) {
                model.LoginDataSource.GetPasswordResetConfiguration().then((data) => {
                    model.CanResetPassword(data.AllowPasswordReset);
                    model.CanSendSmsOnResetPassword(data.AllowSendSms);
                    model.CanSendEmailOnResetPassword(data.AllowSendEmail);
                    model.EmployeeNumberCaption(data.EmployeeNumberCaption);
                    var alternatives = [];
                    if (data.AllowSendSms) {
                        alternatives.push({ id: "0", display: Gatsoft.UI.Resources.get("mg.login.otp.mobilephone") });
                    }
                    if (data.AllowSendEmail) {
                        alternatives.push({ id: "4", display: Gatsoft.UI.Resources.get("mg.login.otp.workemail") });
                    }
                    model.SendAlternatives(alternatives);
                    model.PasswordDeliveryMethodLoaded(true);
                }).always(() => session.end());

            });

            currentPageIndex.subscribe((newValue) => {
                setTimeout(() => {
                    model.LoadedPage(newValue);
                }, 800);
            });

            this.gotoForgotPasswordPage = function () {
                model.OneTimePasswordModel().Username(model.Username());
                model.OneTimePasswordModel().EmployeeCodeOrSsn("");
                model.OneTimePasswordModel().Username.isModified(false);
                model.OneTimePasswordModel().EmployeeCodeOrSsn.isModified(false);
                model.Username.isModified(false);
                model.Password.isModified(false);
                currentPageIndex(-1);
            }

            this.gotoFirstPage = function () {
                currentPageIndex(0);
            };

            this.gotoPrevPage = function () {
                var currentIndex = model.CurrentPageIndex();
                if (currentIndex == 1) {
                    currentPageIndex(0);
                }
                else if (currentIndex == 4 || currentIndex == 5) {
                    currentPageIndex(0);
                    model.clearLoginFields();
                }
                else if (currentIndex > 0) {
                    currentPageIndex(currentIndex - 1);
                }
                else if (currentIndex <= -1) {
                    currentPageIndex(0);
                }
                else {
                    currentPageIndex(currentIndex - 1);
                }
            };

            this.gotoChangePasswordPage = function () {
                model.setInfo("");
                currentPageIndex(3);
            }

            this.gotoTwoFactorPage = function () {
                model.ProgressText(model.defaultLoadingText);
                currentPageIndex(4);
            }

            this.verifyCodeAndContinue = function (twoFactorCode: string, twoFactorChallengeType: number) {
                model.TwoFactorCode(twoFactorCode);
                model.TwoFactorChallengeType(twoFactorChallengeType);
                return this.login();
            }

            this.login = function () {
                model.Password($("#password").val());
                if (model.isValid()) {
                    $("#dummyLoginButton").click();
                    model.ProgressText(Gatsoft.UI.Resources.get("mg.login.loadingLoggingIn"));
                    var loginParam = {
                        Username: model.Username(),
                        Password: model.Password(),
                        TwoFactorCode: model.TwoFactorCode(),
                        TwoFactorChallengeType: model.TwoFactorChallengeType()
                    };

                    model.ForcedPasswordChange(false);
                    model.ShowMoreOnLoginFailed(false);
                    return model.Progress.waitScope.waitWhile(function (session) {
                        model.LoginDataSource.Login(loginParam)
                            .done(function (data) {
                                switch (data.ResultType) {
                                    case 0:
                                        WebApi.WebApiClientBase.antiForgeryToken = data.NewAntiForgeryToken;
                                        WebApi.WebApiClientBase.bearerToken = data.BearerToken;
                                        model.ChangePasswordModel().OldPassword(model.Password());
                                        if (model.ChangePassordOnLogin()) {
                                            model.gotoChangePasswordPage();
                                        } else {
                                            model.returnToStartPage();
                                        }
                                        break;
                                    case 1:
                                        model.ChangePasswordModel().OldPassword(model.Password());
                                        model.ForcedPasswordChange(true);
                                        currentPageIndex(3);
                                        model.setInfo(Gatsoft.UI.Resources.get("mg.login.mustChangePassword"));
                                        break;
                                    case 2:
                                        model.setError(Gatsoft.UI.Resources.get("mg.login.loginFailed"));
                                        model.ShowMoreOnLoginFailed(true);
                                        currentPageIndex(0);
                                        model.clearLoginFields();
                                        break;
                                    case 3:
                                        model.setError(Gatsoft.UI.Resources.get("mg.login.loginFailedUserBlocked"));
                                        currentPageIndex(0);
                                        model.clearLoginFields();
                                        break;
                                    case 4:
                                        model.ChangePasswordModel().OldPassword(model.Password());
                                        currentPageIndex(2);
                                        WebApi.WebApiClientBase.antiForgeryToken = data.NewAntiForgeryToken;
                                        break;
                                    case 5:
                                        model.setError(Gatsoft.UI.Resources.get("mg.login.loginFailedOTPExpired"));
                                        currentPageIndex(0);
                                        break;
                                    case 6:
                                        model.gotoTwoFactorPage();
                                        break;
                                    case 7:
                                        model.ProgressText(model.defaultLoadingText);
                                        if (data.DelayedSeconds > 0)
                                            model.TwoFactorViewModel.setDelayedStatus(data.DelayedSeconds);
                                        else
                                            model.TwoFactorViewModel.setErrorStatus();
                                        break;
                                    default:
                                        model.setError(Gatsoft.UI.Resources.get("mg.login.loginFailedUnknown"));
                                        currentPageIndex(0);
                                        model.clearLoginFields();
                                        break;
                                }
                            })
                            .always(function () {
                                session.end();
                            });
                    });
                } else {
                    this.Errors.showAllMessages(true);
                }

                return $.Deferred().resolve().promise();
            }

            this.logout = function () {
                model.Progress.waitScope.waitWhile(function (session) {
                    model.LoginDataSource.Logout().done(() => {
                        window.location.reload(); // Need to reload page to update antiforgerytoken.
                        /*
                        model.AllreadyLoggedIn(false);
                        model.LoggedInName("");
                        model.LoggedInUsername("");
                        */
                    }).always(() => {
                        session.end();
                    });
                });
            }

            this.returnToStartPage = function () {
                model.Progress.waitScope.startWait(); //unlimited wait since we are switching page.
                model.ChangePasswordModel().tearDown();
                document.location.href = '' + model.afterLoggedInUrl;
            };

            this.requestOneTimePassword = function () {
                var mainModel: LoginModel = this;
                var model: OneTimePasswordModel = mainModel.OneTimePasswordModel();
                if (model.isValid()) {
                    if (model.DeliveryMethod() != "0" && model.DeliveryMethod() != "1" &&
                        model.DeliveryMethod() != "2" && model.DeliveryMethod() != "3" && model.DeliveryMethod() != "4") {
                        currentPageIndex(0);
                        mainModel.setError("Failure: Delivery method not allowed");
                        return;
                    }
                    var data =
                    {
                        Username: model.Username(),
                        EmployeeNumber: model.EmployeeCodeOrSsn(),
                        DeliveryMethod: parseInt(model.DeliveryMethod()),
                    };

                    mainModel.Progress.waitScope.waitWhile((session) => {
                        mainModel.LoginDataSource.RequestOneTimePassword(data).then((resultData) => {
                            if (!resultData.Success) {
                                mainModel.setError(resultData.Message);
                            } else {
                                mainModel.setInfo(Gatsoft.UI.Resources.get("mg.login.otp.oneTimePasswordSent"));
                            }
                            currentPageIndex(0);
                        }).always(() => session.end());
                    });
                }
                else {
                    model.Errors.showAllMessages(true);
                }
            };

            this.changePassword = function () {
                var mainModel: LoginModel = this;
                var model: ChangePasswordModel = mainModel.ChangePasswordModel();
                if (model.isValid()) {
                    var data =
                    {
                        Username: model.Username(),
                        OldPassword: model.OldPassword(),
                        NewPassword: model.NewPassword()
                    };

                    mainModel.Progress.waitScope.waitWhile((session) => {
                        mainModel.LoginDataSource.ChangePassword(data).then((resultData) => {
                            if (!resultData.Success) {
                                mainModel.setError(resultData.Message);
                                return;
                            }
                            mainModel.LoginDataSource.IsLoggedIn().then((result) => {
                                if (!!result)
                                    mainModel.returnToStartPage();
                                else {
                                    mainModel.Password(model.NewPassword());
                                    mainModel.login().then(() => {
                                        mainModel.returnToStartPage();
                                    });
                                }
                            });
                        }).always(() => session.end());
                    });
                }
                else {
                    model.Errors.showAllMessages(true);
                }
            }
        }

        clearLoginFields() {
            this.TwoFactorCode(null);
            this.TwoFactorChallengeType(null);
        }

        setError(message: string) {
            this.StatusMessage(message);
            this.StatusSymbol("error");
        }

        setInfo(message: string) {
            this.StatusMessage(message);
            this.StatusSymbol("info");
        }

        tearDown() {
            this.ChangePasswordModel().tearDown();
        }
    }

    export class OneTimePasswordModel {
        Username: KnockoutObservable<string>;
        EmployeeCodeOrSsn: KnockoutObservable<string>;
        DeliveryMethod: KnockoutObservable<string>;
        Errors: KnockoutValidationErrors;
        isValid: KnockoutComputed<boolean>;

        constructor() {
            var model = this;
            this.Username = ko.observable<string>().extend({ required: true });
            this.EmployeeCodeOrSsn = ko.observable<string>().extend({ required: true });
            this.DeliveryMethod = ko.observable<string>();
            this.Errors = ko.validation.group(this);
        }

    }

    var upperCaseObservable = function (initialValue?: string): KnockoutComputed<string> {
        //private variables
        var actualValue = ko.observable(initialValue);

        //computed observable that we will return
        var result = ko.computed<string>({
            //always return the actual value
            read: function () {
                return (actualValue() || "").toString().toLocaleUpperCase();
            },
            write: function (newValue) {
                actualValue((newValue || "").toString().toLocaleUpperCase());
            }
        });

        return result;
    };

    var dontTouchObservable = function (initialValue?: string): KnockoutComputed<string> {
        //private variables
        var actualValue = ko.observable(initialValue);

        //computed observable that we will return
        var result = ko.computed<string>({
                //always return the actual value
                read: function () {
                    return (actualValue() || "").toString();
                },
                write: function (newValue) {
                    actualValue((newValue || "").toString());
                }
            });

        return result;
    };

    export class ChangePasswordModel {
        Username: KnockoutObservable<string>;
        OldPassword: KnockoutComputed<string>;
        NewPassword: KnockoutComputed<string>;
        NewPasswordAgain: KnockoutComputed<string>;
        Errors: KnockoutValidationErrors;
        isValid: KnockoutComputed<string>;

        constructor(username: KnockoutObservable<string>, loginDataSource: WebApi.LoginDataSource, progress: KnockoutObservable<any>) {
            var model = this;
            model.Username = username;
            model.OldPassword = dontTouchObservable().extend({ required: true });
            model.NewPassword = dontTouchObservable().extend({
                required: true,
                validation: {
                    ruleName: "validateNewPassword.ChangePasswordModel",
                    async: true,
                    validator(val, otherVal, callback)
                    {
                        progress.waitScope.waitWhile(session => {
                            var validatePasswordStrengthModel = { Username: username.peek(), Password: val  };
                            loginDataSource.ValidatePasswordStrength(validatePasswordStrengthModel).done(resultData => {
                                if (resultData.Success) {
                                    callback(true);
                                }
                                else {
                                    callback({ isValid: false, message: resultData.Message });
                                }
                            }).fail(() => {
                                callback({ isValid: false, message: "Error" });
                            }).always(() => {
                                session.end();
                            });
                        });
                    }
                }
            });
            this.NewPasswordAgain = dontTouchObservable().extend({ equal: this.NewPassword });
            this.Errors = ko.validation.group(this);
        }

        tearDown() {
            //ko.validation.removeCustomValidation("ChangePasswordModel");
        }
    }
}